import { useStoreState } from "easy-peasy";
import React from "react";
import { Logo } from "./Icons";
import { NotSubscribed } from "./utils/NotSubscribed";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useIsAdmin, useResolveRootRoles } from "../utils/hooks";
import { isEmpty } from "lodash";

export default function DisplayChildElement({ children, label, searchText }) {
  const { roles } = useResolveRootRoles();

  const findRole = roles.find((role) => {
    if (
      Boolean(role.checked) &&
      role.parent === "Settings" &&
      label.includes(role.name)
    ) {
      return role;
    }
  });

  if (!findRole) return null;

  return label?.toLowerCase()?.includes(searchText?.toLowerCase()) && children;
}

export const IsPrivileged = ({ children, roleName }) => {
  const savedStaffRoles = useStoreState((state) => state.savedStaffRoles);
  const adminRootRoles = useStoreState((state) => state.rootRoles);
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  let privilege =
    isEmpty(savedStaffRoles) || isAdmin ? adminRootRoles : savedStaffRoles;

  const isDataArray = Array.isArray(roleName);

  function checkArray(arrayRole) {
    let check;
    for (
      let rolePrivilege = 0;
      rolePrivilege < arrayRole.length;
      rolePrivilege++
    ) {
      const findRole = privilege.find((role) => {
        return (
          role.name.toLowerCase() === arrayRole[rolePrivilege].toLowerCase() &&
          role.checked
        );
      });
      if (findRole) {
        check = true;
        break;
      } else {
        check = false;
      }
    }

    return check;
  }

  const unauthorized = (
    <div
      className="vw-100 d-flex align-items-center justify-content-center text-center "
      style={{ height: "60vh" }}
    >
      <div>
        <Logo />
        <h4 className="display-5"> Unauthorized</h4>{" "}
        <Button
          onClick={() => navigate(-1)}
          variant=""
          className="text-primary text-underline"
        >
          Go back
        </Button>
      </div>
    </div>
  );

  if (isDataArray) {
    if (Boolean(checkArray(roleName))) {
      return children;
    } else {
      return unauthorized;
    }
  } else {
    const checkPrivilege = privilege.find((role) => {
      return role.name.toLowerCase() === roleName.toLowerCase() && role.checked;
    });

    if (checkPrivilege) {
      return children;
    } else {
      return unauthorized;
    }
  }
};
